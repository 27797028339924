import { OrderStatus } from "@/shared/types/OrderStatus";
import { QuotationStatus } from "@/shared/types/QuotationStatus";
import { CustomStepData } from "@/shared/components/CustomSteps.vue";

/** Extra data for instructions below shotlist steps (CustomSteps) in Profile (dashboard) */
export type OrderStatusStepInstructionsData = {
  hideDescriptionTitle?: boolean;
  descriptionTitle?: string;
  description?: string | string[];
  /** Hides the entire shotlist instructions and buttons. */
  hideShotlistInstructions?: boolean;
  /** Description to show in the "Take Action" card above.
   * This overrides `description` that is shown by default when the step has an action. */
  takeActionDescription?: string | string[];
  descriptionColor?: "red";
};

/** Abstract type for Step Data and Action Data */
export type BaseOrderStatusData = {
  /** Array of OrderStatus to use this step data. */
  orderKeys?: OrderStatus[];
  /** Array of QuotationStatus to use this step data. */
  quotationKeys?: QuotationStatus[];
};

export type BaseOrderStatusStepData = BaseOrderStatusData &
  OrderStatusStepInstructionsData &
  CustomStepData;

export type ConditionalOrderStatusStepData = Partial<BaseOrderStatusStepData> &
  BaseOrderStatusData;

/** An type that extends BaseOrderStatusData. Has array of keys for matching. */
export type OrderStatusStepData = BaseOrderStatusStepData & {
  /**
   * For specific keys, you can use different values.
   * Just add the specific keys in the `keys: []` array,
   * and set the values to override.
   */
  conditionalValues?: ConditionalOrderStatusStepData[];
};

/**
 * A generic utility function to get OrderStatus's StepData or ActionData
 * @param key OrderStatus enum or it's string representation (see graphql schema for values)
 * @param arr Pass the array where to search for the key. Must implement `OrderStatusStepData[]` or `OrderStatusActionData[]`
 */
export const findOrderStatusData = <
  T extends
    | OrderStatusStepData
    | ConditionalOrderStatusStepData
    | OrderStatusActionData
    | OrderStatusFAQData
>(
  key: string,
  arr: T[],
  useQuotationStatus?: boolean
): T | undefined => {
  if (useQuotationStatus) {
    return arr.find((status) =>
      status.quotationKeys?.map((s) => s.toString()).includes(key)
    );
  }

  return arr.find((status) =>
    status.orderKeys?.map((s) => s.toString()).includes(key)
  );
};

// ===== OrderStatus ActionData =====

/** An type that extends BaseOrderStatusData. Has array of keys for matching. */
export type OrderStatusActionData = BaseOrderStatusData & {
  actions: OrderStatusActionHandler[];
};

export type OrderStatusActionHandler = {
  label: string;
  handler: () => void;
};

/**
 * Utility helper function that creates an OrderStatus ActionData hander.
 * Used only to shorten the code in components (eliminates enclosing curly braces).
 * @param label Label of the action button
 * @param handler Function to call on click
 */
export const createOrderStatusActionHandler = (
  label: string,
  handler: () => void
): OrderStatusActionHandler => {
  return {
    label: label,
    handler: handler,
  };
};

// ==== OrderStatus FAQ =====
export type OrderStatusFAQ = {
  question?: string;
  answer: string;
  /** Placed on NextStepCard instead of FAQs. */
  isNextStep?: boolean;
  // For action button
  nextStepAction?: () => void;
  nextStepActionLabel?: string;
};

export type OrderStatusFAQData = BaseOrderStatusData & {
  faqs: OrderStatusFAQ[];
};

export const QUOTATION_STATUS = {
  NEW: "NEW",
  CLIENT_SUBMITTED: "CLIENT_SUBMITTED",
  PAID: "PAID",
  CONTENT_WRITER_REQUEST: "CONTENT_WRITER_REQUEST",
  FOR_CHECKOUT: "FOR_CHECKOUT",
  ORDERED: "ORDERED",
};

export type QuotationStatus = keyof typeof QUOTATION_STATUS;

import {
  Order_order_Order,
  Order_order_Order_quotation,
} from "@/api/order/__generated__/Order";
import { Orders_orders_OrderResults_results } from "@/api/order/__generated__/Orders";
import { LatestQuotation_latestQuotation_Quotation } from "@/api/quotations/__generated__/LatestQuotation";
import { Quotation_quotation_Quotation } from "@/api/quotations/__generated__/Quotation";
import { i18nTranslate } from "@/plugins/i18n";
import { formatDate } from "@/shared/utils/date";
import { QUOTATION_STATUS } from "../types/QuotationStatus";

export type OrderType = Order_order_Order | Orders_orders_OrderResults_results;

export type QuotationType =
  | LatestQuotation_latestQuotation_Quotation
  | Quotation_quotation_Quotation
  | Order_order_Order_quotation;

export const getOrderName = (options: {
  quotation?: QuotationType;
  order?: OrderType;
}): string => {
  const order = options.order;
  const quotation = options.quotation || options.order?.quotation;

  // No actual order yet, use current date
  if (quotation && !order) {
    const date = formatDate(new Date().toString(), "monthYearInWords");
    return date;
  }

  // Has Order, either with or without Quotation
  if (order) {
    const date = formatDate(order.created, "monthYearInWords");
    return i18nTranslate("#{id} - {date}", {
      id: order?.id,
      date: date,
    });
  }

  return "";
};

export const getQuotationPackageName = (
  quotation: QuotationType | null | undefined
): string => {
  if (!quotation || quotation.status?.key === QUOTATION_STATUS.NEW) {
    return "";
  }

  return quotation.useTestimonialPackage
    ? i18nTranslate("Testimonial")
    : i18nTranslate("Basic");
};

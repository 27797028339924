import { i18nTranslate } from "@/plugins/i18n";
import { MERCHANT_PAYMENT_EMAIL } from "@/shared/utils/constants";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { openInNewTab } from "@/shared/utils/browser";
import {
  createOrderStatusActionHandler,
  findOrderStatusData,
  OrderStatusActionData,
  OrderStatusFAQ,
  OrderStatusFAQData,
  OrderStatusStepData,
} from "@/shared/utils/orderStatusHelper";
import { computed, Ref } from "vue";
import { config } from "@/shared/utils/config";
import { VIDEO_ICON_SRC } from "@/shared/utils/constants";
import { CustomStepData } from "@/shared/components/CustomSteps.vue";
import {
  formatDistanceToNowStrict,
  dateFormats,
  formatDate,
} from "@/shared/utils/date";
import { format, isToday } from "date-fns";
import { useRedirectHelper } from "./useRedirectHelper";
import { QuotationStatus } from "@/shared/types/QuotationStatus";
import { OrderType, QuotationType } from "@/shared/utils/orderUtils";
import { useConfig } from "./useConfig";
import filter from "lodash/filter";

/**
 * Composable function to help with providing values related to Order's status and actions.
 * @param orderRef A reactive Ref variable with the value of order query
 * @returns Helper variables and functions
 */
export const useOrderStatusHelper = (
  orderRef: Ref<OrderType | null | undefined>,
  quotationRef?: Ref<QuotationType | null | undefined>
) => {
  const quotation = computed(
    () => quotationRef?.value || orderRef.value?.quotation
  );

  /** Check for Quotation Status if not yet Ordered */
  const useQuotationStatus = computed<boolean>(
    () => !!quotation.value && !orderRef.value
  );

  const {
    redirectToReviewRequests,
    redirectToPerformerApplications,
    redirectToOrderShippingDetails,
    redirectToHomepage,
    redirectToQuote,
  } = useRedirectHelper();

  const { configValue: contentRequestFormLink } = useConfig(
    "CONTENT_REQUEST_FORM_LINK"
  );

  // FIXME: Duplicated code from `useOrder` composable
  const useVideoApprovalFeature = computed<boolean>(
    () => !orderRef.value?.videoOutputLink
  );

  // Prevent reactive nature of computed (might be expensive)
  const now = new Date();

  const showTargetDate = computed(
    () =>
      ![
        OrderStatus.CLIENT_FEEDBACK,
        OrderStatus.DONE,
        OrderStatus.CANCELED,
      ].includes(orderRef.value?.status?.key as OrderStatus) &&
      orderRef.value?.targetDate &&
      new Date(orderRef.value.targetDate) > now
  );

  /** Used in "Expect in {x} days" or "today" */
  const targetDateString = computed(() => {
    if (!orderRef.value?.targetDate) {
      return "";
    }

    const today = isToday(new Date(orderRef.value.targetDate));

    if (today) {
      return i18nTranslate("Expect {date}", {
        date: i18nTranslate("today"),
      });
    } else if (new Date(orderRef.value.targetDate) >= now) {
      const dateStr = formatDistanceToNowStrict(orderRef.value.targetDate, {
        unit: "day",
        roundingMethod: "ceil",
        addSuffix: true,
      });

      return i18nTranslate("Expect {date}", {
        date: dateStr,
      });
    } else {
      return formatDate(
        orderRef.value.targetDate,
        "dateWithNoTimeInWords_dayFirst"
      );
    }
  });

  /**
   * All is based on OrderStatus key, or QuotationStatus key
   */
  const currentStepKey = computed<OrderStatus | QuotationStatus>(() => {
    // Use Quotation Status if Order is not yet available
    if (useQuotationStatus.value) {
      return quotation.value?.status?.key as QuotationStatus;
    } else {
      // Else, use Order Status
      if (orderRef.value && orderRef.value.status) {
        return orderRef.value.status.key as OrderStatus;
      }

      return OrderStatus._NULL;
    }
  });

  /** Used for CustomStep component */
  const currentStepIndex = computed(() =>
    getOrderStatusIndex(currentStepKey.value)
  );

  const currentStepData = computed(() => {
    return getOrderStatusData(currentStepIndex.value, currentStepKey.value);
  });

  const currentStepFaq = computed(() => {
    const allFaqs = getOrderStatusFaq(currentStepKey.value);
    return {
      faqs: filter(allFaqs, (faq) => !faq.isNextStep) as OrderStatusFAQ[],
      nextStep: filter(allFaqs, (faq) => faq.isNextStep).at(0) as
        | OrderStatusFAQ
        | undefined,
    };
  });

  const targetDateInstructions = computed(() => {
    if (!orderRef.value?.targetDate) return "";

    const targetDateHTML = `
        <span style="color: #c5575b; font-size: 1.375rem; margin-left: 0.2em;">
          ${VIDEO_ICON_SRC}
          <strong>${format(
            new Date(orderRef.value.targetDate),
            dateFormats.dateWithNoTimeInWords
          )}*</strong>
        </span>
      `;

    const footNoteHTML = `<p>*Target date</p>`;

    return targetDateHTML + footNoteHTML;
  });

  const currentStepActions = computed(() => {
    return getOrderStatusActions(currentStepKey.value);
  });

  /** === Custom Steps and Actions for Video Approval Flow === */
  const targetDateSubtitle = computed(() =>
    showTargetDate.value ? targetDateString.value : ""
  );

  /** Custom Step Data for Video Approval Feature. See: #865c1bn95 */
  const orderStatusStepsData = computed<OrderStatusStepData[]>(() => [
    // Step 1: New Quotation
    {
      quotationKeys: ["NEW"],
      title: i18nTranslate("Quotation"),
      takeActionDescription: i18nTranslate(
        "Answer the Pricing Calculator to know how much your videos will cost."
      ),
      description: i18nTranslate(
        "Pricing Calculator is self-service. You can check how much your video cost in that page."
      ),
      iconName: "calculator",
    },
    // Step 2: Pay
    {
      quotationKeys: ["CLIENT_SUBMITTED"],
      title: i18nTranslate("Pay"),
      description: i18nTranslate(
        "We checked your quotation request. This is good to go. Please expect an email from us in 1-2 days."
      ),
      iconName: "money",
      subtitle: i18nTranslate("Expect in 1-2 biz days"),
    },
    // Step 3 (Optional): Content Writer Request
    ...((quotation.value?.hireStrategist
      ? [
          {
            quotationKeys: ["PAID", "CONTENT_WRITER_REQUEST"],
            title: i18nTranslate("Content Writer"),
            subtitle: i18nTranslate("Expect in 2-3 biz days"),
            takeActionDescription: i18nTranslate(
              "Answer your Content Writer Request form."
            ),
            description: i18nTranslate(
              "You added a content writer add-on means that someone else will write the content for you. You can submit all your requests and idea in the Content Writer Request Form."
            ),
            iconName: "pencil",
          },
        ]
      : []) as OrderStatusStepData[]),
    // Step 4: For Checkout
    {
      quotationKeys: ["PAID", "FOR_CHECKOUT"],
      title: i18nTranslate("Order Videos"),
      takeActionDescription: quotation.value?.hireStrategist
        ? // With Content Writer AddOn
          i18nTranslate(
            "The content writer submitted their draft in the platform. You can now view them. If you already finished ordering, you can ignore this step."
          )
        : // Without Content Writer AddOn
          i18nTranslate(
            "You can now order in the platform. If you already finished ordering, you can ignore this step."
          ),
      description: quotation.value?.hireStrategist
        ? // With Content Writer AddOn
          i18nTranslate(
            "The content writer submitted draft video ideas based on your request. You can check their work in the platform. If you have anything you want to change, you can freely change it in the platform."
          )
        : // Without Content Writer AddOn
          i18nTranslate(
            "All the information you will put in your order will be sent to the model creators. Make sure you give all the instructions properly and clearly."
          ),
      iconName: "cart",
    },
    // Step 5: Ideation
    {
      orderKeys: [
        OrderStatus.NEW,
        OrderStatus.NEW,
        OrderStatus.PAID,
        OrderStatus.QA1,
        OrderStatus.QA1_EDIT,
        OrderStatus.QA1_APPROVED,
      ],
      title: i18nTranslate("QA Check"),
      description: [
        i18nTranslate(
          `<b style="color: #c5575b">Unpaid:</b> Please send your order number to {email}.`,
          { email: MERCHANT_PAYMENT_EMAIL }
        ),
        i18nTranslate(
          `<b>Paid:</b> Please wait as our QA team checks your order. Please check your email for further notification.`
        ),
      ],
      subtitle: i18nTranslate("Expect in 1-2 biz days"),
      iconName: "pencil",
      takeActionDescription: i18nTranslate(
        "SEND EMAIL: If you haven't paid for this order, please send the order number to {email}. Your order will not proceed until we've confirmed your payment. If you have paid already, ignore this step.",
        { email: MERCHANT_PAYMENT_EMAIL }
      ),

      conditionalValues: [
        {
          orderKeys: [OrderStatus.QA1_EDIT],
          takeActionDescription: i18nTranslate(
            "Our QA1 has questions / revisions. Please check your email."
          ),
          description: i18nTranslate(
            "Our internal quality assurance team flagged something in your order. Please check your email. Until that is resolved, we cannot proceed with the next step."
          ),
        },
      ],
    },
    // Step 6: Select Creator, Assigning Creator
    {
      orderKeys: [OrderStatus.SELECT_CREATOR, OrderStatus.SELECTING_CREATOR],
      title: i18nTranslate("Select Model Creator"),
      iconName: "userStep",
      conditionalValues: [
        {
          orderKeys: [OrderStatus.SELECTING_CREATOR],
          title: i18nTranslate("Assigning Model Creator"),
          subtitle: i18nTranslate("Expect in 2-3 biz days"),
          description: i18nTranslate(
            "We're choosing the best matching model creators for your brand! Sit tight!"
          ),
        },
        {
          orderKeys: [OrderStatus.SELECT_CREATOR],
          takeActionDescription: i18nTranslate(
            "Time to choose your model creator. Please choose one that fits your vision for your brand. Remember, please choose within 2 days otherwise, {merchantBrand} will choose for you!",
            { merchantBrand: config.merchantBrandShortName }
          ),
          description: i18nTranslate(
            "Time to choose your model creator. You can choose based on what you need. Our suggestion, choose someone who represents your target audience."
          ),
          hideDescriptionTitle: true,
        },
      ],
    },
    // Step 7: Ship Product
    {
      orderKeys: [OrderStatus.SHIP_PRODUCT, OrderStatus.IDEATION],
      title: i18nTranslate("Ship Products"),
      description: [
        i18nTranslate(
          "Kindly ship your products using your preferred courier and send us the tracking number by clicking the button. For any concerns or special requests, please send us a message. Refer to the shipping instructions indicated."
        ),
        // Append shipping instructions
        // orderRef.value?.shipmentInstructions ?? "",
      ],
      iconName: "truck",
      takeActionDescription: i18nTranslate(
        "After you shipped the product, please enter the shipping/tracking details here."
      ),
    },
    // Step 8: Production
    {
      orderKeys: [OrderStatus.PRODUCTION],
      title: i18nTranslate("Shooting Videos"),
      descriptionTitle: i18nTranslate("We are shooting your videos"),
      description: [
        i18nTranslate("Model Creators are shooting your videos."),
        targetDateInstructions.value,
      ],
      iconName: "videoCamera",
    },
    // Step 9: QA
    {
      orderKeys: [OrderStatus.QA],
      title: i18nTranslate("Creative Check"),
      descriptionTitle: i18nTranslate("We are reviewing your videos"),
      description: [
        i18nTranslate(
          "To ensure that all videos created in the kamila platform is at par to our standard, our internal Creative Team is reviewing the video submitted by our model creator."
        ),
        targetDateInstructions.value,
      ],
      iconName: "pencil",
    },
    // Step 10: Video Approval / Client Review Jobs
    {
      orderKeys: [OrderStatus.CLIENT_REVIEW],
      title: i18nTranslate("Select Videos"),
      subtitle: targetDateSubtitle.value,
      showSubtitleAlways: true,
      takeActionDescription: i18nTranslate(
        'Please select your videos here. You can also input your notes once you click "Select Videos".'
      ),
      description: i18nTranslate(
        "This is our promise - we check each and every video created in our platform to guarantee the quality."
      ),
      iconName: "filePlay",
    },
    // Step 11: Feedback
    {
      orderKeys: [OrderStatus.CLIENT_FEEDBACK],
      title: i18nTranslate("Feedback"),
      description: i18nTranslate(
        "This is our promise - we check each and every video created in our platform to guarantee the quality."
      ),
      iconName: "star",
    },
    // Step 12: Done / Canceled
    {
      orderKeys: [OrderStatus.DONE, OrderStatus.CANCELED],
      title: i18nTranslate("DONE"),
      iconName: "check",
      hideShotlistInstructions: true,
      conditionalValues: [
        {
          orderKeys: [OrderStatus.CANCELED],
          title: i18nTranslate("Canceled"),
          iconName: "cancel",
          description: i18nTranslate(
            "Choose the kind of videos you like us to do for your business."
          ),
          hideShotlistInstructions: false,
        },
      ],
    },
  ]);

  /**
   * CTA handler functions for different OrderStatus
   * You can exclude OrderStatus that has no actions
   */
  const orderStatusActions = computed<OrderStatusActionData[]>(() => [
    {
      quotationKeys: ["NEW"],
      actions: [
        createOrderStatusActionHandler(
          i18nTranslate("Request for Quote"),
          () => {
            redirectToQuote();
          }
        ),
      ],
    },
    // Show Payment Link Action
    ...((orderRef.value?.paymentLink
      ? [
          {
            quotationKeys: ["CLIENT_SUBMITTED"],
            actions: [
              createOrderStatusActionHandler(i18nTranslate("Pay Now"), () => {
                openInNewTab(orderRef.value?.paymentLink ?? "");
              }),
            ],
          },
        ]
      : []) as OrderStatusActionData[]),
    {
      quotationKeys: ["CONTENT_WRITER_REQUEST"],
      actions: [
        createOrderStatusActionHandler(
          i18nTranslate("Answer Content Request Form"),
          () => {
            openInNewTab(contentRequestFormLink.value);
          }
        ),
      ],
    },
    {
      quotationKeys: ["FOR_CHECKOUT"],
      actions: [
        createOrderStatusActionHandler(
          i18nTranslate("Choose Templates"),
          () => {
            redirectToHomepage();
          }
        ),
      ],
    },
    {
      orderKeys: [OrderStatus.SELECT_CREATOR],
      actions: [
        createOrderStatusActionHandler(
          i18nTranslate("Choose Model Creator"),
          () => {
            if (!orderRef.value?.id) {
              return;
            }

            redirectToPerformerApplications({ orderId: orderRef.value.id });
          }
        ),
      ],
    },
    {
      orderKeys: [OrderStatus.SHIP_PRODUCT, OrderStatus.IDEATION],
      actions: [
        createOrderStatusActionHandler(
          i18nTranslate("Send Tracking Details"),
          () => {
            if (!orderRef.value?.id) {
              return;
            }

            redirectToOrderShippingDetails({ orderId: orderRef.value.id });
          }
        ),
      ],
    },
    {
      orderKeys: [OrderStatus.CLIENT_REVIEW],
      actions: [
        createOrderStatusActionHandler(i18nTranslate("Select Videos"), () => {
          // Redirect to Video Output Link (backwards compat on old flow)
          if (!useVideoApprovalFeature.value) {
            openInNewTab(orderRef.value?.videoOutputLink);
            return;
          }

          if (!orderRef.value?.id) {
            return;
          }

          redirectToReviewRequests({ orderId: orderRef.value.id });
        }),
      ],
    },
    {
      orderKeys: [OrderStatus._NULL, OrderStatus.CANCELED],
      actions: [
        createOrderStatusActionHandler(i18nTranslate("Order Video"), () => {
          redirectToHomepage();
        }),
      ],
    },
  ]);

  /**
   * CTA handler functions for different OrderStatus
   * You can exclude OrderStatus that has no actions
   */
  const orderStatusFaqs = computed<OrderStatusFAQData[]>(() => [
    {
      quotationKeys: ["NEW"],
      faqs: [
        {
          isNextStep: true,
          answer: i18nTranslate(
            "After this, we will reach out to your registered email with a payment link. "
          ),
        },
      ],
    },
    {
      quotationKeys: ["CLIENT_SUBMITTED"],
      faqs: [
        {
          question: i18nTranslate("How can I pay?"),
          answer: i18nTranslate(
            "Our payment system is still under development. For now, you will receive either a Stripe Payment or a NextPay Payment."
          ),
        },
        {
          question: i18nTranslate("What are the modes of payment?"),
          answer: i18nTranslate(
            "If you have a committed number of months, you need to pay via credit card. Otherwise, bank and Gcash are also your option."
          ),
        },
        {
          isNextStep: true,
          answer: i18nTranslate("You can start ordering your videos."),
        },
      ],
    },
    {
      quotationKeys: ["CONTENT_WRITER_REQUEST"],
      faqs: [
        {
          isNextStep: true,
          answer: i18nTranslate(
            "We will upload the content writer submission in your dashboard."
          ),
        },
      ],
    },
    {
      quotationKeys: ["PAID", "FOR_CHECKOUT"],
      faqs: [
        {
          question: i18nTranslate("Can I change my order?"),
          answer: i18nTranslate(
            "Yes you can until we've assigned a model creator for you."
          ),
        },
        {
          isNextStep: true,
          answer: i18nTranslate(
            "Our QA team will review your order if this is something we can accommodate."
          ),
        },
      ],
    },
    {
      orderKeys: [
        OrderStatus.NEW,
        OrderStatus.PAID,
        OrderStatus.QA1,
        OrderStatus.QA1_EDIT,
      ],
      faqs: [
        {
          question: i18nTranslate("If I have not paid, how can I pay:"),
          answer: i18nTranslate(
            "Please send your order number to {email}. We will send you a payment link after.",
            { email: MERCHANT_PAYMENT_EMAIL }
          ),
        },
        {
          question: i18nTranslate(
            "When and where can I get the contents brief if I hired a writer?"
          ),
          answer: i18nTranslate(
            "After you answer the request form, you will receive an email notification in 2-4 days and you will see the details below in this page."
          ),
        },
        {
          isNextStep: true,
          answer: i18nTranslate("Model Creator Choosing/Assigning"),
        },
      ],
    },
    {
      orderKeys: [OrderStatus.SELECT_CREATOR],
      faqs: [
        {
          question: i18nTranslate("Can I choose model creators?"),
          answer: i18nTranslate(
            'Yes you can choose since you purchased the add-on "choose model creator".'
          ),
        },
        {
          question: i18nTranslate("Is my chosen model creator final?"),
          answer: i18nTranslate(
            "No. It also depends on the availability of the model creator. If your preferred model creator is not available, we will ask you to choose another model creator."
          ),
        },
        {
          isNextStep: true,
          answer: i18nTranslate("Ship the product"),
        },
      ],
    },
    {
      orderKeys: [OrderStatus.SELECTING_CREATOR],
      faqs: [
        {
          question: i18nTranslate("Can I choose model creators?"),
          answer: i18nTranslate(
            'No, unless you purchase the add-on "choose model creator".'
          ),
        },
        {
          question: i18nTranslate(
            "What if I don't like model creators who are assigned by {merchantBrand}?",
            {
              merchantBrand: config.merchantBrand,
            }
          ),
          answer: i18nTranslate(
            'If you want to specifically choose model creator because you have the clear image of your persona, please kindly choose add-on "choose model creator".'
          ),
        },
        {
          isNextStep: true,
          answer: i18nTranslate("Model Creator will shoot the video"),
        },
      ],
    },
    {
      orderKeys: [OrderStatus.SHIP_PRODUCT],
      faqs: [
        {
          question: i18nTranslate("Why can't I use Lalamove/Grab to ship?"),
          answer: i18nTranslate(
            `If it's same day delivery, we need to monitor shipping and coordinate both your side and the Model Creator's side. It takes too much time and effort. If your products are consumable (eg frozen food and easy-to-spoil food) or are too big or too heavy for courier shipping, please purchase a "on-demand" delivery add-on.`
          ),
        },
        {
          isNextStep: true,
          answer: i18nTranslate("Model Creator will shoot the video"),
        },
      ],
    },
    {
      orderKeys: [OrderStatus.PRODUCTION],
      faqs: [
        {
          isNextStep: true,
          answer: i18nTranslate("Creative Team Checking"),
        },
      ],
    },
    {
      orderKeys: [OrderStatus.QA],
      faqs: [
        {
          isNextStep: true,
          answer: i18nTranslate("Choose your video"),
        },
      ],
    },
    {
      orderKeys: [OrderStatus.CLIENT_REVIEW],
      faqs: [
        {
          question: i18nTranslate("Can I request to edit videos?"),
          answer: i18nTranslate(
            "NO, unless the edits fall into any of these categories: <br/>" +
              "• Wrong information: the model creator mentioned a wrong information in the video regarding your product. <br/>" +
              "• Typo/grammar error <br/>" +
              "• Mispronounced product name <br/>" +
              "• Video content instruction was not followed <br/>" +
              "• Change background music"
          ),
        },
        {
          isNextStep: true,
          answer: i18nTranslate("Feedback"),
        },
      ],
    },
    {
      orderKeys: [OrderStatus.DONE],
      faqs: [
        {
          isNextStep: true,
          answer: i18nTranslate(
            "This order is done! Request for your next videos now."
          ),
          nextStepActionLabel: i18nTranslate("Order Videos"),
          nextStepAction: () => {
            redirectToHomepage();
          },
        },
      ],
    },
  ]);

  /** Steps to use in <CustomSteps> (a-steps) component */
  const shotlistSteps = computed((): CustomStepData[] => {
    return orderStatusStepsData.value.map((_, index) => {
      return getOrderStatusData(index)!;
    });
  });

  /**
   * Get the index of the step based on key. Multiple keys can represent a single step.
   * @param key OrderStatus enum or it's string representation (see graphql schema for values)
   */
  const getOrderStatusIndex = (key: string): number => {
    if (useQuotationStatus.value) {
      return orderStatusStepsData.value.findIndex((status) =>
        status.quotationKeys?.map((s) => s.toString()).includes(key)
      );
    }

    return orderStatusStepsData.value.findIndex((status) =>
      status.orderKeys?.map((s) => s.toString()).includes(key)
    );
  };

  /**
   * Gets the step data, taking conditionalValues into account when `key` is provided.
   * @param index Current index
   * @param key Check for conditional value
   */
  const getOrderStatusData = (index: number, key?: string) => {
    const stepData = orderStatusStepsData.value.at(index);

    if (!stepData) return null;

    if (stepData.conditionalValues && key) {
      const conditionalData = findOrderStatusData(
        key,
        stepData.conditionalValues
      );

      if (conditionalData) {
        // Only overrides properties specified in conditionalData
        return Object.assign(stepData, conditionalData);
      }
    }

    // Return original step data if there's no supplied specific one (based on conditional)
    return stepData;
  };

  /**
   * Get the actions of the step based on key.
   * @param key OrderStatus enum or it's string representation (see graphql schema for values)
   */
  const getOrderStatusActions = (key: string) => {
    return (
      findOrderStatusData(
        key,
        orderStatusActions.value,
        useQuotationStatus.value
      )?.actions ?? []
    );
  };

  const getOrderStatusFaq = (key: string) => {
    return (
      findOrderStatusData(key, orderStatusFaqs.value, useQuotationStatus.value)
        ?.faqs ?? []
    );
  };

  return {
    currentStepKey,
    currentStepIndex,
    currentStepData,
    currentStepActions,
    currentStepFaq,
    orderStatusStepsData,
    orderStatusActions,
    orderStatusFaqs,
    getOrderStatusData,
    getOrderStatusActions,
    getOrderStatusFaq,
    shotlistSteps,
    targetDateString,
  };
};
